import classNames from 'classnames'
import React, { useEffect, useMemo, useRef } from 'react'

import KeyEventKeys from '../../constants/KeyEventKeys'
import useGlobalStartPage from '../../hooks/globalContent/useGlobalStartPage'
import useMicroCopy from '../../hooks/globalContent/useMicroCopy'
import useOffices from '../../hooks/globalContent/useOffices'
import { usePartnersData } from '../../hooks/globalContent/usePartnersData'
import useIsNavMenuOpen from '../../hooks/navMenu/useIsNavMenuOpen'
import useNavMenuControls from '../../hooks/navMenu/useNavMenuControls'
import usePageLink from '../../hooks/routing/usePageLink'
import useFixedElementPageTextColor from '../../hooks/useFixedElementPageTextColor'
import useIsDesktop from '../../hooks/useIsDesktop'
import useTabLock from '../../hooks/useTabLock'
import useWindowKeyEvent from '../../hooks/useWindowKeyEvent'
import { disablePageScroll, enablePageScroll } from '../../utils/scrollUtils'
import LanguageSelector from '../LanguageSelector'
import SocialMediaLinks from '../SocialMediaLinks'
import PartnersBlock from '../_blocks/PartnersBlock/PartnersBlock'
import OfficeCard from '../_cards/OfficeCard'
import { InternalLink } from '../_elements/InternalLink/InternalLink'
import MenuIcon from '../_elements/MenuIcon'

import { useNavMenuLinks, useScrollNavVisibility } from './NavMenu.hooks'
import SubNavMenu from './SubNavMenu'

import styles from './nav-menu.module.scss'

interface NavMenuProps {
  iconsColor?: string | null
}

export default function NavMenu({ iconsColor }: NavMenuProps) {
  const isDesktop = useIsDesktop()
  const navMenuRef = useRef<HTMLDivElement>(null)
  const desktopNavRef = useRef<HTMLDivElement>(null)
  const menuTextColor = useFixedElementPageTextColor(desktopNavRef)
  const { isVisible, hasScrolled } = useScrollNavVisibility()

  const navMenuLinks = useNavMenuLinks()
  const technologiesTitle = useMicroCopy('text.technologies')
  const servicesTitle = useMicroCopy('text.services')

  const globalStartPage = useGlobalStartPage()
  const technologiesPage = globalStartPage?.pages?.find(
    (page) => page.slug === 'technologies'
  )
  const technologiesLink = technologiesPage
    ? usePageLink(technologiesPage.slug)
    : '/technologies'

  const offices = useOffices()
  const limitedOffices = useMemo(
    () => offices.slice(0, isDesktop ? 3 : 4),
    [offices, isDesktop]
  )

  const isOpen = useIsNavMenuOpen()
  const { close, toggle } = useNavMenuControls()

  const menuBackgroundRef = useRef<HTMLDivElement | null>(null)

  useWindowKeyEvent(KeyEventKeys.Escape, onEscapePressed)

  const { lockTabFocus, unlockTabFocus } = useTabLock(menuBackgroundRef)

  useEffect(() => {
    if (isOpen) {
      lockTabFocus()
      disablePageScroll()

      return () => {
        unlockTabFocus()
        enablePageScroll()
      }
    }
  }, [isOpen])

  function onOverlayClick() {
    close()
  }

  function onEscapePressed() {
    if (isOpen) {
      close()
    }
  }

  function onMenuLinkClick() {
    // For gatsby to calculate a correct scroll position for the new route
    enablePageScroll()
  }

  const { partners } = usePartnersData()

  return (
    <div ref={navMenuRef}>
      {!isDesktop && (
        <button
          className={styles.button}
          id="menu-button"
          onClick={toggle}
          aria-label={isOpen ? 'Hide navigation menu' : 'Show navigation menu'}
          aria-haspopup={true}
          aria-expanded={isOpen}
          aria-controls="menu-flyout"
        >
          <MenuIcon closedColor={iconsColor} isOpen={isOpen} />
        </button>
      )}

      {isDesktop && (
        <div
          className={classNames(
            styles.desktopNav,
            hasScrolled && styles.scrolled,
            !isVisible && styles.hidden
          )}
          ref={desktopNavRef}
          style={{ position: 'fixed' }}
        >
          <ul
            className={styles.desktopNavList}
            style={{ color: menuTextColor || iconsColor || undefined }}
          >
            {navMenuLinks.map((navMenuLink) => {
              if (!navMenuLink.subpages)
                return (
                  <li
                    key={navMenuLink.to + navMenuLink.label}
                    className={styles.desktopNavListItem}
                    onClick={onMenuLinkClick}
                  >
                    <InternalLink
                      className={styles.desktopNavLink}
                      activeClassName={styles.desktopNavLinkActive}
                      to={navMenuLink.to}
                      segmentdata={{
                        anchor_text: navMenuLink.label,
                        position: 'navigation',
                        url: navMenuLink.to,
                      }}
                    >
                      {navMenuLink.label}
                    </InternalLink>
                  </li>
                )
              else if (navMenuLink.subpages)
                return (
                  <li
                    key={navMenuLink.to + navMenuLink.label}
                    className={styles.desktopNavListItem}
                  >
                    <div className={styles.desktopSubNavContainer}>
                      <InternalLink
                        className={classNames(
                          styles.desktopNavLink,
                          styles.desktopNavLinkWithSub
                        )}
                        activeClassName={styles.desktopNavLinkActive}
                        to={navMenuLink.to}
                        segmentdata={{
                          anchor_text: navMenuLink.label,
                          position: 'navigation',
                          url: navMenuLink.to,
                        }}
                      >
                        {navMenuLink.label}
                      </InternalLink>
                      <div className={styles.desktopDropdown}>
                        <div className={styles.desktopDropdownRow}>
                          {/* Services Section */}
                          <div className={styles.desktopDropdownSection}>
                            <h3 className={styles.desktopDropdownTitle}>
                              {servicesTitle}
                            </h3>
                            <div className={styles.desktopDropdownColumns}>
                              {navMenuLink.subpages
                                .filter(
                                  (page) =>
                                    page.navigationTitle &&
                                    page.navigationTitle.trim() !== ''
                                )
                                .map((page, index) => (
                                  <div
                                    key={index}
                                    className={styles.desktopDropdownItem}
                                    onClick={onMenuLinkClick}
                                  >
                                    <InternalLink
                                      activeClassName={
                                        styles.desktopDropdownLinkActive
                                      }
                                      to={page.link}
                                      segmentdata={{
                                        anchor_text: page.navigationTitle,
                                        position: 'navigation',
                                        url: page.link,
                                      }}
                                    >
                                      {page.navigationTitle}
                                    </InternalLink>
                                  </div>
                                ))}
                            </div>
                          </div>

                          {/* Technologies Section */}
                          <div className={styles.desktopDropdownSection}>
                            <h3 className={styles.desktopDropdownTitle}>
                              {technologiesTitle}
                            </h3>
                            {partners && partners.length > 0 && (
                              <PartnersBlock
                                __typename="ContentfulBlockPartners"
                                title=""
                                partners={partners}
                                isInDropdown={true}
                                selectedOnly={true}
                              />
                            )}
                            <div className={styles.seeAllLink}>
                              <InternalLink
                                to={technologiesLink}
                                segmentdata={{
                                  anchor_text: 'See all',
                                  position: 'navigation',
                                  url: technologiesLink,
                                }}
                              >
                                See all &gt;
                              </InternalLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
            })}
          </ul>
        </div>
      )}

      <div
        className={classNames(styles.overlay, isOpen && styles.overlayIsOpen)}
        onClick={onOverlayClick}
      />

      <div
        className={classNames(
          styles.background,
          isOpen && styles.backgroundIsOpen
        )}
        id="menu-flyout"
        aria-labelledby="menu-button"
        aria-hidden={!isOpen}
      >
        <div className={styles.backgroundInner}>
          <nav className={styles.nav}>
            <ul className={styles.navList}>
              {navMenuLinks.map((navMenuLink) => {
                if (!navMenuLink.subpages)
                  return (
                    <li
                      key={navMenuLink.to + navMenuLink.label}
                      className={styles.navListItem}
                      onClick={onMenuLinkClick}
                    >
                      <InternalLink
                        className={classNames(
                          styles.navLink,
                          navMenuLink.subpages && styles.navLinkWithSub
                        )}
                        activeClassName={styles.navLinkActive}
                        to={navMenuLink.to}
                        tabIndex={isOpen ? undefined : -1}
                        segmentdata={{
                          anchor_text: navMenuLink.label,
                          position: 'navigation',
                          url: navMenuLink.to,
                        }}
                      >
                        {navMenuLink.label}
                      </InternalLink>
                    </li>
                  )
                else if (navMenuLink.subpages)
                  return (
                    <SubNavMenu
                      key={navMenuLink.to + navMenuLink.label}
                      navItem={navMenuLink}
                    />
                  )
              })}
            </ul>
          </nav>

          <ul className={styles.officeList}>
            {limitedOffices.map((office) => (
              <li key={office.id} className={styles.officeItem}>
                <OfficeCard
                  className={styles.office}
                  office={office}
                  isCondensed={!isDesktop}
                  isLargerText={isDesktop}
                  isDisabledLinks={!isOpen}
                />
              </li>
            ))}
          </ul>

          <div className={styles.socialContainer}>
            <LanguageSelector
              className={styles.languageSelector}
              isHorizontal={!isDesktop}
              isDisabledLinks={!isOpen}
            />
            <SocialMediaLinks
              className={styles.socialMediaLinks}
              isDisabledLinks={!isOpen}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
