import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import useGlobalStartPage from '../../hooks/globalContent/useGlobalStartPage'
import useMicroCopy from '../../hooks/globalContent/useMicroCopy'
import { usePartnersData } from '../../hooks/globalContent/usePartnersData'
import useIsNavMenuOpen from '../../hooks/navMenu/useIsNavMenuOpen'
import usePageLink from '../../hooks/routing/usePageLink'
import { enablePageScroll } from '../../utils/scrollUtils'
import PartnersBlock from '../_blocks/PartnersBlock/PartnersBlock'
import { InternalLink } from '../_elements/InternalLink/InternalLink'

import { NavMenuLink } from './NavMenu.hooks'

import styles from './nav-menu.module.scss'

interface SubNavMenuProps {
  navItem: NavMenuLink
}

export default function SubNavMenu({ navItem }: SubNavMenuProps) {
  const [subIsOpen, setSubIsOpen] = useState(false)
  const [useTransition, setUseTransition] = useState(false)

  const servicesTitle = useMicroCopy('text.services')
  const technologiesTitle = useMicroCopy('text.technologies')

  const isOpen = useIsNavMenuOpen()

  const { partners } = usePartnersData()

  const globalStartPage = useGlobalStartPage()
  const technologiesPage = globalStartPage?.pages?.find(
    (page) => page.slug === 'technologies'
  )
  const technologiesLink = technologiesPage
    ? usePageLink(technologiesPage.slug)
    : '/technologies'

  function onMenuLinkClick() {
    enablePageScroll()
  }

  useEffect(() => {
    if (typeof window == 'undefined') return
    const pathName = window.location.pathname
    const mainSubpage = navItem.to.toLocaleLowerCase()
    const active = pathName.includes(mainSubpage)

    if (!active) {
      setSubIsOpen(false)
    } else if (active && isOpen) {
      setUseTransition(false)
      setSubIsOpen(true)
    }
  }, [isOpen])

  return (
    <>
      <li key={navItem.to + navItem.label} className={styles.navListItem}>
        <button
          tabIndex={isOpen ? undefined : -1}
          title={navItem.label}
          onClick={() => {
            setSubIsOpen(!subIsOpen)
            setUseTransition(true)
          }}
          className={classNames(
            styles.navLink,
            navItem.subpages && styles.navLinkWithSub,
            subIsOpen && styles.navLinkWithSubActive,
            useTransition && styles.navLinkTransition
          )}
        >
          {navItem.label}
        </button>
      </li>
      <div
        className={classNames(
          styles.subContainer,
          subIsOpen && styles.showSub,
          useTransition && styles.navLinkTransition
        )}
      >
        {/* Services Section */}
        <div className={styles.mobileSection}>
          <h3 className={styles.mobileSectionTitle}>{servicesTitle}</h3>
          <div className={styles.mobileColumns}>
            {navItem.subpages?.map((page, index) =>
              page.navigationTitle ? (
                <div
                  key={index}
                  className={styles.mobileMenuItem}
                  onClick={onMenuLinkClick}
                >
                  <InternalLink
                    activeClassName={styles.subNavLinkActive}
                    to={page.link}
                    tabIndex={subIsOpen ? 0 : -1}
                    segmentdata={{
                      anchor_text: page.navigationTitle,
                      position: 'navigation',
                      url: page.link,
                    }}
                  >
                    {page.navigationTitle}
                  </InternalLink>
                </div>
              ) : null
            )}
          </div>
        </div>

        {/* Technologies Section */}
        <div className={styles.mobileSection}>
          <h3 className={styles.mobileSectionTitle}>{technologiesTitle}</h3>
          {partners && (
            <PartnersBlock
              __typename="ContentfulBlockPartners"
              title={technologiesTitle}
              partners={partners}
              isInDropdown={true}
              selectedOnly={true}
            />
          )}
          <div className={styles.seeAllLink}>
            <InternalLink
              to={technologiesLink}
              segmentdata={{
                anchor_text: 'See all',
                position: 'navigation',
                url: technologiesLink,
              }}
            >
              See all &gt;
            </InternalLink>
          </div>
        </div>
      </div>
    </>
  )
}
